/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300&display=swap');


body {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white !important;
  overflow-x:hidden !important;
  letter-spacing: 1px;
  scroll-behavior: smooth;
}
::selection{
  background-color: #014AA8;
  color: white;
} 
::-webkit-scrollbar{
  width: 0px;
}