*{
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
}
.Manufacturing-main-container{
    background: transparent linear-gradient(143deg,#f8feff,#e2f8ff) 0 0 no-repeat padding-box;
    border-radius: 5px;
    box-shadow: 0px 15px 31px -3px rgba(0,0,0,0.1);
}
.Manufacturing-main-text{
    font-size: 40px;
    position: relative;
    color: #0b0757;
}
.Manufacturing-main-text::before{
    content: '';
    margin-top: 10px;
    width: 200px;
    height: 5px;
    position: absolute;
    bottom: -20px;
    color: transparent;
    background: transparent linear-gradient(99deg, #5338FF 0%,#FFC465 100%) 0% 0% no-repeat padding-box;
    border-radius: 5px;
}

.Manufacturing-img-container{
    background: transparent linear-gradient(143deg,#f8feff,#e2f8ff) 0 0 no-repeat padding-box;
    border-radius: 5px;
}
.Manufacturing-img-box{
    background-color: white;
    border-radius: 5px;
}
/* .Manufacturing-img-box:hover .img-fluid{
    transform: scale(0.9);
    cursor: pointer;
    transition: all 0.4s linear;
} */
@media (max-width:450px) {
    .Manufacturing-main-text{
        font-size: 27px;
        position: relative;
        color: #0b0757;
    }
    .Manufacturing-main-text::before{
        width: 160px; 
        height: 5px;
    }
}
@media (max-width:325px) {
    .Manufacturing-main-text::before{
        width: 80px;
        height: 5px;
    }
}