@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Lato:wght@900&family=Poppins:wght@200;300&display=swap');

.after-scroll {
    background: transparent linear-gradient(143deg, #e2f8ff, #e2f8ff) 0 0 no-repeat padding-box;

}

.before-scroll {
    background-color: white;
}

.app.light {
    background-color: #f8f8f8 !important;
    color: #333 !important;
}

.app.dark {
    background-color: #333 !important;
    color: #f8f8f8 !important;
}

.navbar-container {
    box-shadow: 0px 15px 31px -3px rgba(0, 0, 0, 0.1);
    overflow-x: hidden !important;
}

.logo {
    border-radius: 50%;
}

.company-text {
    color: #0b0757;
    text-decoration: none;
    font-weight: bold;
    opacity: 100%;
}

.contact-btn:hover {
    background-color: #fff;
    color: #014AA8;
}

.navbar-links {
    color: rgb(0, 0, 0);
    text-decoration: none;
    font-size: 15px;
    opacity: 70%;
}

.active {
    color: #004bab;
    text-decoration: none;
    font-size: 15px;
    font-weight: bold;
    cursor: auto;
}

.navbar-links:hover {
    color: #004bab;
    opacity: 100%;
    opacity: 100%;
}

@media (max-width:992px) {
    .LogoRound {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }

    .navbar-left {
        justify-content: space-between !important;
    }

    .li {
        line-height: 70px;
        font-weight: bold;
        opacity: 1 !important;
    }
}

@media (max-width:450px) {
    .company-text {
        color: #0b0757;
        text-decoration: none;
        font-size: 16px !important;
        font-weight: 600;
        opacity: 100%;
    }

    .logo {
        width: 60px !important;
        height: 60px !important;
        border-radius: 50%;
    }

    .li {
        line-height: 70px;
        font-weight: bold;
        opacity: 1 !important;
    }
}


@media (max-width:325px) {
    .company-text {
        color: #0b0757;
        font-size: 14px !important;
    }

    .logo {
        width: 55px !important;
        height: 55px !important;
        border-radius: 50%;
    }
}


@media (max-width:299px) {
    .company-text {
        color: #0b0757;
        font-size: 12px !important;
    }

    .logo {
        width: 45px !important;
        height: 45px !important;
        border-radius: 50%;
    }
}