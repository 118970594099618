
.footer-main-container{
    line-height: 35px;
    overflow-x:hidden !important;
    background: transparent linear-gradient(143deg,#e2f8ff,#e2f8ff) 0 0 no-repeat padding-box;
}
.footer-text{
    text-align: center;
    opacity: 1 !important;
    font-size: 12px;
    letter-spacing: 2px;
}
.footer-main-link{
    text-decoration: none;
    color: #0b0757;
}
.footer-main-link:hover{
    color:blue;
}
.footer-link{
    color:black;
    display: inline-block;
    transition: all 0.3s;
    text-decoration: none;
}
.footer-link:hover{
    color:blue;
    text-decoration: underline;
    transform: translateX(5px);
}


.reach-us-icons{
    color: #4169e1;
    font-size: 25px !important;
}
.reach-us-icons:hover{
    color: blue;
    font-size: 25px !important;
}
.footer-title{
    font-size: 15px;
    font-weight: bold;
    color: #0b0757;
}
.social-media-icons:hover{
    transform: scale(1.2);
}
@media (max-width:580px) {
    .footer-border-left-links{
        border-left: 2px solid #004bab;
        padding: 0px 20px;
    }
}
@media (max-width:450px) {
    .footer-text{
        font-size: 10px;
        font-weight: normal;
        letter-spacing: 1px;
    }
}