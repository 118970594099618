*{
    margin: 0%;
    padding: 0%;
}
.contact-main-container{
    margin-top: 80px;
    margin-bottom: 30px;
}
.contact-main-text{
    font-size: 45px;
    position: relative;
    color: #0b0757;
}
.contact-main-text::before{
    content: '';
    width: 200px;
    height: 5px;
    position: absolute;
    left: 50%; 
    transform: translateX(-50%); 
    background: linear-gradient(99deg, #5338FF 0%, #FFC465 100%);
    border-radius: 5px;
    bottom: -10px;
    z-index: -1; 
}
.contact-box{
    height: 200px;
    box-shadow: 0px 15px 31px -3px rgba(0,0,0,0.1);
    border: 1px solid #4169e1;
    border-radius: 5px;
}
.contact-link{
    text-decoration: none;
    color: gray;
}
.contact-link:hover{
    color: #0b0757;
}
.contact-icons{
    color: #4169e1;
}
.contact-title{
color: #0b0757;
}
.input-box{
    border: 1px solid  #0b0757;
    outline: none;
    overflow-x:hidden !important;
}
.input-box:focus{
    border: 1px solid  blue;
}
.error{
    color: red;
}
.map-frame{
    width: 100% !important;
    height: 400px;
}
@media (max-width:450px) {
    .contact-main-text{
        font-size: 22px;
        position: relative;
        color: #0b0757;
        padding:0px 10px;
    }
    .contact-box{
        box-shadow: none;
    }
    .contact-main-container{
        margin-top: 70px;
    }
    .contact-main-text::before{
    width: 160px;
    }
}
@media (max-width:325px) {
    .contact-main-text::before{
        width: 80px;
        }
}