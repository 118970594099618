.products-main-box{
    margin-top: 80px;
}
.products-main-text{
    font-size: 40px;
    position: relative;
    color: #0b0757;
}
.products-main-text::before{
    content: '';
    width: 200px;
    height: 5px;
    position: absolute;
    left: 50%; 
    transform: translateX(-50%); 
    background: linear-gradient(99deg, #5338FF 0%, #FFC465 100%);
    border-radius: 5px;
    bottom: -10px;
    z-index: -1; 
}
.content-sub-text{
    color: #0b0757;
}
.product-shadow{
    transform: translateX(-100px);
    background-color: #fff;
    box-shadow: 0px 15px 31px -3px rgba(0,0,0,0.1);
    border-radius: 5px;
}
.products-sub-text{
    font-size: 30px;
    font-weight: bold;
    color: #0b0757;
}
.products-advantage-title{
    font-size: 23px;
    font-weight: bold;
    color: #0b0757;
}
@media (max-width:1000px) {
    .product-shadow{
        transform: translateX(0px);
        background-color: #fff;
        box-shadow: none;
    }
   
}
@media (max-width:450px) {
    .products-main-text{
        font-size: 25px;
        position: relative;
        color: #0b0757;
    }
    .products-main-title{
        text-align: center;
        font-weight: bold;
        font-size: 25px;
        padding: 0px 15px;
    }
    .products-sub-text{
        font-size: 25px !important;
        font-weight: bold;
        color: #0b0757;
    }
    .products-advantage-title{
        font-size: 18px;
        font-weight: bold;
        color: #0b0757;
    }
    .content-sub-text{
        color: #0b0757;
        font-size: 18px;
    }
    .products-main-box{
        margin-top: 75px;
    }
    .products-main-text::before{
        width: 160px; 
        height: 5px;
    }
}
@media (max-width:325px) {
    .products-main-text{
        font-size: 23px;
        position: relative;
        color: #0b0757;
    }
    .products-main-title{
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        padding: 0px 15px;
    }
    .products-main-box{
        margin-top: 40px;
    }
    .products-sub-text{
        font-size: 20px;
        font-weight: bold;
        color: #0b0757;
    }
    .products-advantage-title{
        font-size: 18px;
        font-weight: bold;
        color: #0b0757;
    }
    .content-sub-text{
        color: #0b0757;
        font-size: 18px;
    }
    .products-main-text::before{
        width: 80px;
        height: 5px;
    }
}