.project-main-container{
    margin-top: 80px;
}
.projects-main-text{
    font-size: 35px;
    position: relative;
    color: #0b0757;
}
.projects-main-text::before{
    content: '';
    width: 200px;
    height: 5px;
    position: absolute;
    left: 0%; 
    background: linear-gradient(99deg, #5338FF 0%, #FFC465 100%);
    border-radius: 5px;
    bottom: -10px;
    z-index: -1; 
}
.active-tab{
    background-color: blue;
    color: white;
    padding: 20px;
    outline: none;
    border: 0px;
}   
.grayImg{
    filter: grayscale(1);
}
.colorImg{
    filter: grayscale(0);
}
.projects-sub-text{
    font-size: 18px !important;
    font-weight:600;
    color: #0b0757;
    /* background:transparent linear-gradient(143deg,#e2f8ff,#e2f8ff) 0 0 no-repeat padding-box; */
    display: inline-block;
}

.card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); 
  }
  
  .projects-sub-text {
    letter-spacing: 2px !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; 
  }
  
  .projectDetailsBox{
    margin-top: 140px;
  }
  
@media (max-width:450px) {
    .projects-main-text{
        font-size: 25px;
        position: relative;
        color: #0b0757;
    }
    .projectDetailsBox{
        margin-top: 90px;
      }
    .projects-main-text::before{
        content: '';
        width: 160px;
        height: 5px;
        position: absolute;
        left: 0%; 
        background: linear-gradient(99deg, #5338FF 0%, #FFC465 100%);
        border-radius: 5px;
        bottom: -10px;
        z-index: -1; 
    }
    .project-main-container{
        margin-top: 70px;
    }
    .projects-sub-text{
        font-size: 20px !important;
        font-weight:600;
        color: #0b0757;
    }
    
}
@media (max-width:325px) {
    .projects-sub-text{
        font-size: 19px !important;
        font-weight:600;
        color: #0b0757;
    }
    .projects-main-text{
        font-size: 22px;
        position: relative;
        color: #0b0757;
    }
    .projects-main-text::before{
        content: '';
        width: 80px;
        height: 5px;
        position: absolute;
        left: 0%; 
        background: linear-gradient(99deg, #5338FF 0%, #FFC465 100%);
        border-radius: 5px;
        bottom: -10px;
        z-index: -1; 
    }
}